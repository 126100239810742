<template>
  <ct-centered-spinner v-if="!loaded" />
  <div v-else id="associated-pages-component">
    <h6 v-if="getPagesToShow.length === 0" class="no-pages-text">
      No Pages Yet
    </h6>
    <a v-for="(page, index) in getPagesToShow"
       :key="page.id"
       @click="logFaqAssociatedPageNavigation(categoryId, page.id, { action: 'visit', type: 'link', categoryId: categoryId, pageQuestion: page.question })"
    >
      <div class="associated-page-container">
        <h6 class="associated-page-text">{{ page.question }}</h6>
        <arrow-right class="arrow-icon" />
      </div>
      <hr v-if="index < getPagesToShow.length - 1">
    </a>
  </div>
</template>

<script>
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'AssociatedPages',
  components: {
    CtCenteredSpinner,
    ArrowRight: () => import('@images/ui/arrow-right-231F20.svg'),
  },
  mixins: [faqMixin],
  props: {
    categoryId:{
      type: String,
    },
    maxPages: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    getPagesToShow() {
      const category = this.faqInformation.find(cat => cat.id === this.categoryId)
      return category?.faq_pages.filter(page => page.is_visible).slice(0, this.maxPages)
    },
  },
  async mounted() {
    if(!this.faqInformation) {
      await this.fetchFaqInformation()
    }
    this.loaded = true
  },
}
</script>

<style lang="scss" scoped>
#associated-pages-component {
  .associated-page-text {
    margin: 16px 0 16px 0;
    color: #231F20;
  }
  .associated-page-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 37px;
  }
  .arrow-icon {
    width: 21px;
    height: 24px;
  }
  .no-pages-text {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  a:focus h6{
    border: 1px solid orange;
    border-radius: 3px;
  }
  a:hover {
    cursor: pointer;
  }
  hr {
    width: 90%;
  }
}
</style>
