var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loaded
    ? _c("ct-centered-spinner")
    : _c(
        "div",
        { attrs: { id: "associated-pages-component" } },
        [
          _vm.getPagesToShow.length === 0
            ? _c("h6", { staticClass: "no-pages-text" }, [
                _vm._v("\n    No Pages Yet\n  "),
              ])
            : _vm._e(),
          _vm._l(_vm.getPagesToShow, function (page, index) {
            return _c(
              "a",
              {
                key: page.id,
                on: {
                  click: function ($event) {
                    return _vm.logFaqAssociatedPageNavigation(
                      _vm.categoryId,
                      page.id,
                      {
                        action: "visit",
                        type: "link",
                        categoryId: _vm.categoryId,
                        pageQuestion: page.question,
                      }
                    )
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "associated-page-container" },
                  [
                    _c("h6", { staticClass: "associated-page-text" }, [
                      _vm._v(_vm._s(page.question)),
                    ]),
                    _c("arrow-right", { staticClass: "arrow-icon" }),
                  ],
                  1
                ),
                index < _vm.getPagesToShow.length - 1 ? _c("hr") : _vm._e(),
              ]
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }